import React from 'react'
import { Link, graphql } from 'gatsby'

import Bio from 'components/bio'
import Layout from 'components/layout'
import SEO from 'components/seo'
import { rhythm } from 'utils/typography'
import { PostNodeT } from 'templates/blog-post/types'

// Necessary for css prop for ts
import {} from '@emotion/core'

type PropsT = {
  location: string
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
  }
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const BlogIndex = ({ data, location }: PropsT) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Home" />
      <Bio />
    </Layout>
  )
}

export default BlogIndex
